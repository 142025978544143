// Import all of Bootstrap's JS
import * as bootstrap from "../../../node_modules/bootstrap";
// TODO Switch from auto to reduce bundle size https://www.chartjs.org/docs/next/getting-started/integration.html
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const GRAPH_FONT_COLOR_LIGHT = "#111111";
const GRAPH_FONT_COLOR_DARK = "#e3f5fc";
const GRAPH_COLORS = {
    blue: "#1888FA",
    purple: "#8474E8",
    lightGreen: "#7CE391",
    turquoise: "#08C8CC",
    orange: "#ECB52B",
};
const GRAPH_FONT = {
    family: "ABeeZee, sans-serif",
    size: 18,
};
const GRAPH_OPTIONS = {
    maintainAspectRatio: false,
    barPercentage: 0.5,
    categoryPercentage: 1,
    indexAxis: "y",
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            grid: {
                display: false
            },
            border: {
                display: false,
            },
            ticks: {
                color: GRAPH_FONT_COLOR_LIGHT,
                font: GRAPH_FONT,
            }
        },
        x: {
            display: false,
            grid: {
                display: false
            },
            beginAtZero: true
        }
    },
};
const GRAPH_DATA_LABELS = {
    align: "right",
    anchor: "end",
    color: GRAPH_FONT_COLOR_LIGHT,
    display: "true",
    font: GRAPH_FONT,
};

const compressionRatioGraphCtx = document.getElementById("compression-ratio-graph").getContext("2d");
const compressionRatioGraphProps = {
    data: {
        labels: ["CLP", "Zstandard", "Splunk", "Elasticsearch"],
        datasets: [{
            data: [40.2, 19.5, 2.9, 1.8],
            backgroundColor: [
                GRAPH_COLORS.blue,
                GRAPH_COLORS.purple,
                GRAPH_COLORS.lightGreen,
                GRAPH_COLORS.turquoise,
            ],
            borderWidth: 0
        }]
    },
    formatter: (value, ctx) => value + "x",
};
let compressionRatioGraph = new Chart(compressionRatioGraphCtx, {
    type: "bar",
    data: compressionRatioGraphProps.data,
    options: {
        ...GRAPH_OPTIONS,
        ...{
            layout: {
                padding: {
                    left: 0,
                    right: 11,
                    top: 0,
                    bottom: 0,
                }
            },
            plugins: {
                ...GRAPH_OPTIONS.plugins,
                datalabels: {
                    ...GRAPH_DATA_LABELS,
                    ...{formatter: compressionRatioGraphProps.formatter}
                },
            }
        }
    },
    plugins: [ChartDataLabels]
});

const searchSpeedGraphCtx = document.getElementById("search-speed-graph").getContext("2d");
const searchSpeedGraphProps = {
    data: {
        labels: ["CLP", "Elasticsearch", "Splunk", "Ripgrep"],
        datasets: [{
            data: [57.5, 7.7, 2.1, 1.0],
            backgroundColor: [
                GRAPH_COLORS.blue,
                GRAPH_COLORS.turquoise,
                GRAPH_COLORS.lightGreen,
                GRAPH_COLORS.orange,
            ],
            borderWidth: 0
        }]
    },
    formatter: (value, ctx) => value + "x",
};
let searchSpeedGraph = new Chart(searchSpeedGraphCtx, {
    type: "bar",
    data: searchSpeedGraphProps.data,
    options: {
        ...GRAPH_OPTIONS,
        ...{
            layout: {
                padding: {
                    left: 0,
                    right: 50,
                    top: 0,
                    bottom: 0,
                }
            },
            plugins: {
                ...GRAPH_OPTIONS.plugins,
                datalabels: {
                    ...GRAPH_DATA_LABELS,
                    ...{formatter: searchSpeedGraphProps.formatter}
                },
            }
        }
    },
    plugins: [ChartDataLabels]
});

const ingestionSpeedGraphCtx = document.getElementById("ingestion-speed-graph").getContext("2d");
const ingestionSpeedGraphProps = {
    data: {
        labels: ["CLP", "RE2", "Elasticsearch"],
        datasets: [{
            data: [320, 64, 1],
            backgroundColor: [
                GRAPH_COLORS.blue,
                GRAPH_COLORS.lightGreen,
                GRAPH_COLORS.turquoise,
            ],
            borderWidth: 0
        }]
    },
    formatter: (value, ctx) => value + "MB/s",
};
let ingestionSpeedGraph = new Chart(ingestionSpeedGraphCtx, {
    type: "bar",
    data: ingestionSpeedGraphProps.data,
    options: {
        ...GRAPH_OPTIONS,
        ...{
            layout: {
                padding: {
                    left: 0,
                    right: 50,
                    top: 0,
                    bottom: 0,
                }
            },
            plugins: {
                ...GRAPH_OPTIONS.plugins,
                datalabels: {
                    ...GRAPH_DATA_LABELS,
                    ...{formatter: ingestionSpeedGraphProps.formatter}
                },
            }
        }
    },
    plugins: [ChartDataLabels]
});

const html = document.querySelector("html");

function switchTheme(theme) {
    if (theme === "dark") {
        // document.querySelector(".theme-icon").classList.remove("bi-moon");
        // document.querySelector(".theme-icon").classList.add("bi-brightness-high");

        compressionRatioGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_DARK;
        compressionRatioGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_DARK;
        searchSpeedGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_DARK;
        searchSpeedGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_DARK;
        ingestionSpeedGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_DARK;
        ingestionSpeedGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_DARK;

        localStorage.setItem("ui-theme", "dark");
    } else if (theme === "light") {
        // document.querySelector(".theme-icon").classList.remove("bi-brightness-high");
        // document.querySelector(".theme-icon").classList.add("bi-moon");

        compressionRatioGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_LIGHT;
        compressionRatioGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_LIGHT;
        searchSpeedGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_LIGHT;
        searchSpeedGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_LIGHT;
        ingestionSpeedGraph.options.scales.y.ticks.color = GRAPH_FONT_COLOR_LIGHT;
        ingestionSpeedGraph.options.plugins.datalabels.color = GRAPH_FONT_COLOR_LIGHT;

        localStorage.setItem("ui-theme", "light");
    }
    html.dataset.bsTheme = `${theme}`;
    compressionRatioGraph.update();
    searchSpeedGraph.update();
    ingestionSpeedGraph.update();
}

let theme = localStorage.getItem("ui-theme");
if (theme === "light" || theme === "dark") {
    switchTheme(theme);
} else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    switchTheme("dark");
} else {
    switchTheme("light");
}

const toggleTheme = function () {
    let theme = localStorage.getItem("ui-theme");
    if (theme === "dark")
        switchTheme("light");
    else if (theme === "light")
        switchTheme("dark");
}

document.getElementById("themeSwitcher").addEventListener("click", toggleTheme);
